
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import HomeScreen from "./Pages/HomeScreen";
import AboutScreen from "./Pages/AboutScreen";
import TopNavigation from "./Components/Top/TopNavigation";
import Shop from "./Pages/Shop";
import SingleProduct from "./Pages/SingleProduct";
import ProfileScreen from "./Pages/ProfileScreen";
import OrdersScreen from "./Pages/OrdersScreen";
import ShoppingCart from "./Pages/ShoppingCart";

function App() {
  return (
    <>
       <Router>
          <div className="main-container">
            <TopNavigation />
            <Routes>
                <Route path="/" element={<HomeScreen />}/>  
                <Route path="/about" element={<AboutScreen />}/>  
                <Route path="/shop" element={<Shop />}/> 
                <Route path="/product/:id" element={<SingleProduct />}/>  
                <Route path="/orders/" element={<OrdersScreen />}/>  
                <Route path="/profile/" element={<ProfileScreen />}/>  
                <Route path="/cart/" element={<ShoppingCart />}/>  
            </Routes>
          </div>
       </Router>
       <ToastContainer />
    </>
  );
}

export default App;
