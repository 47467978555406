import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { register, reset } from '../../reduxAuth/authSlice';

function RegisterModal() {
    const emailRef                                                      = useRef();
    const passwordRef                                                   = useRef();
    const nameRef                                                       = useRef();
    const surnameRef                                                    = useRef();
    const professionRef                                                 = useRef();
    const phoneRef                                                      = useRef();

    const dispatch                                                      = useDispatch();

    const {user, isLoading, isError, isSuccess, message}                = useSelector((state) => state.auth);

    useEffect(() => {

        if(isError){
            toast.error(message)
        }
      
        if(isSuccess || user){
            
           console.log("Registration SITUATION")
           toast.success(message)
        }

        dispatch(reset())
    },[user, isError, isSuccess, message, dispatch])


    const handleRegistration = (e) => {
        e.preventDefault();
        try{

            const titleObject = {
                "email" : emailRef.current.value,
                "password": passwordRef.current.value,
                "name" : nameRef.current.value,
                "surname" : surnameRef.current.value,
                "profession" :  professionRef.current.value,
                "phone" : phoneRef.current.value
            }
            
            console.log(titleObject);

            dispatch(register(titleObject));
        }catch(err){
            console.log(err);
        }
    }

  return (
    <div className="block-form">
        <div className="form-inner"> 
             <form onSubmit={handleRegistration}>
                <div className="form-group frg mgtop10">
                    <div className="text-form">Email*: </div>
                    <input type="email" className="form-control ct-content wide100" ref={emailRef}  placeholder="Enter Email Address" required/>
                </div>
                <div className="form-group frg mgtop10">
                    <div className="text-form">Password*: </div>
                    <input type="password" className="form-control ct-content wide100"  ref={passwordRef} placeholder="Enter Password" required/>
                </div>
                <div className="form-group frg mgtop10">
                    <div className="text-form">Name*: </div>
                    <input type="text" className="form-control ct-content wide100" ref={nameRef}  placeholder="Enter Your Name" required/>
                </div>
                <div className="form-group frg mgtop10">
                    <div className="text-form">Surname*: </div>
                    <input type="text" className="form-control ct-content wide100"  ref={surnameRef} placeholder="Enter Your Surname" required/>
                </div>
                <div className="form-group frg mgtop10">
                    <div className="text-form">Profession*: </div>
                    <input type="text" className="form-control ct-content wide100"  ref={professionRef} placeholder="Enter Your Profession" required/>
                </div>
                <div className="form-group frg mgtop10">
                    <div className="text-form">Phone Number: </div>
                    <input type="text" className="form-control ct-content wide100"  ref={phoneRef} placeholder="Enter Your Phone Number" />
                </div>
                <div className="form-group frg mgtop10">
                    <button className="btn btn-action btn-full ct" disabled={isLoading} >Register</button>
                </div>
             </form>
        </div>
    </div>
  )
}

export default RegisterModal