import React from 'react'

function OrdersScreen() {
  return (
    <div>
         <div className="section-row ">
         <div className="container standard-start">OrdersScreen</div>
         </div>
    </div>
  )
}

export default OrdersScreen