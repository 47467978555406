
export const API_URL = "https://cjm-eccommerce-2e864444e6aa.herokuapp.com/api/";
export const API_BASE = "https://cjm-eccommerce-2e864444e6aa.herokuapp.com/";
export const SESSION_COOKIE = "WeAreFreadyForTheBigWorkingout";
export const API_CJMED = "https://cjmedilus-47c38d92aa3d.herokuapp.com/api/";

/*
export const API_URL = "http://localhost:2045/api/";
export const API_BASE = "http://localhost:2045/";
export const SESSION_COOKIE = "userEcommerceCjMarkEting";
*/
export const CART_COOKIE = "caRtCutterManLocalZERORetails";

export const SMALLER_PROCESS = "https://cjmarketing.co/image_directory/loading.gif";
export const SMALL_PROCESSING = "https://cjmarketing.co/image_directory/cj-process1.gif";
export const IMAGE_PROFILE = "https://newclient.cjdsa.co.za/images/base/profile.jpg";
