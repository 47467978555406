import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import * as CONSTANTS from "../CONSTANTS";
import { useDispatch, useSelector } from 'react-redux';
import { addItem, incrementProductQuantity, incrementQuantity } from '../reduxAuth/cartSlice';

function SingleProduct() {
     const cartItems                                                                      = useSelector(state => state.cart.items);

    const params                                                                          = useParams();
    const [productDetails, setProductDetails]                                             = useState();
    const [currentMainImage, setCurrentMainImage]                                         = useState("");
    const [variationCode, setVariationCode]                                               = useState("");
    const [variationList, setVariationList]                                               = useState([]);

    const [processing, setProcessing]                                                     = useState(false);  

    const [variateTitle, setVariateTitle]                                                 = useState();

    const {user}                                                                          = useSelector((state) => state.auth);
  
    const dispatch                                                                        = useDispatch();

    useEffect(() => {
        collectInformation();
        console.log(user);
    },[])

    useEffect(() => {
      if(variationCode.length > 0){
        getVariationProducts();
      }
    },[variationCode])
    
    const collectInformation = async () => {
      try{
        setProcessing(true);
        //console.log(params.id)
        
          const currentID = params.id;
          const res = await axios.get(CONSTANTS.API_URL +"products/details/single/" + currentID);
          
          //console.log(res.data);
          setProductDetails(res.data[0]);
          if(res.data.length > 0){
            setCurrentMainImage(res.data[0].pictureURL);

            ////////////////////////////
            //variation
            /*
            console.log(res.data[0].variation);
            console.log(res.data[0].variation.variationCode);
            console.log(res.data[0].variation.variationCode.length);
            */

            if(res.data[0].variation &&  res.data[0].variation.variationCode &&  res.data[0].variation.variationCode.length > 1){
              console.log("Variation found");
              setVariationCode(res.data[0].variation.variationCode);
            }

          }         
          setProcessing(false);
      }catch(err){
          console.log(err);
          setProcessing(false);
      }
  }

  const getVariationProducts = async () => {
    try{

      console.log(variationCode);
          const res = await axios.get(CONSTANTS.API_URL +"products/list/variation/code/" + variationCode);
          console.log("Ntwana");
          console.log(res.data);
          setVariateTitle(res.data[0].variation.label)
          setVariationList(res.data);
    }catch(err){
      console.log(err)
    }
  }

  const handlVariationItem = (variate) => {
    console.log(variate);
    setProductDetails(variate);
    setCurrentMainImage(variate.pictureURL);
  }

  const handleAddToCart = () => {
    console.log("===========>");
    console.log(productDetails);
    console.log(cartItems);
    const existingItem = cartItems.find(item => item._id === productDetails._id);

      if(existingItem){
        dispatch(incrementQuantity(existingItem._id));
        dispatch(incrementProductQuantity(existingItem._id));
      }else {
        dispatch(addItem(productDetails));
        dispatch(incrementProductQuantity(productDetails._id));
      }


  }

  return (
    <div>
        <div className="section-row ">
            <div className="container standard-start">
                SingleProduct
                <div className="row-single">
                  {
                    processing && (
                      <div className="processing-container">
                        <img src={CONSTANTS.SMALL_PROCESSING} className="image-processing" />
                      </div>
                    )
                  }
                   {
                    productDetails && (
                      <div className="row">
                        <div className="col-md-7">
                            <div className="image-container">
                              <div className="img-base text-center">
                                <img src={currentMainImage} className="main-image-single" />
                              </div>
                              <div className="img-relative">
                                
                              </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                           <div className="content-product">
                                <h2 className="product-title">{productDetails.title}</h2>
                                <div className="red-price">
                                  R {productDetails.price}
                                </div>
                                <div className="short-desc">
                                  {productDetails.shortDescription}
                                </div>
                                <div className="variat-block">
                                   <div className="titling-variation">
                                     {variateTitle}
                                   </div>
                                  {
                                      variationList &&
                                      variationList.length > 0 && (
                                          <div className="flexme variat-flex">
                                            {
                                              variationList.map((variate, index ) => (
                                                <div key={index} className="item-variation" onClick={() => handlVariationItem(variate)}>{variate.variation.value}</div>
                                              ))
                                            }
                                          </div>
                                      )
                                    }
                                </div>
                                
                                {
                                   user === null ?
                                   <div className="cart-button">
                                       <div className="cart-msg">
                                       Login to Add to cart
                                       </div>
                                    </div>
                                    :
                                    <div className="cart-button">
                                        <button className="btn btn-action btn-cart" onClick={handleAddToCart}>Add to Cart</button>
                                    </div>
                                }
                                
                           </div>
                        </div>
                      </div>
                    )
                   }
                </div>
            </div>
        </div>
    </div>
  )
}

export default SingleProduct