import React, { useEffect, useState } from 'react';
import * as CONSTANTS from "../../CONSTANTS";
import './topnavigation.css';
import HeaderLogo from '../../assets/cjmarketing_white.png';
import { Link, useLocation  } from 'react-router-dom';
import { FaBars, FaShoppingBasket } from "react-icons/fa"
import { useDispatch, useSelector } from 'react-redux';
import LoginModal from './LoginModal';
import RegisterModal from './RegisterModal';
import { logout } from '../../reduxAuth/authSlice';



function TopNavigation() {

    const {user}                                                                            = useSelector((state) => state.auth);
    const cartItems                                                                         = useSelector(state => state.cart.items);
    let location                                                                            = useLocation();
    ///console.log(location);
    const classFind =  (location.pathname.length > 1) ? "page-nav" :  "home-nav";
    
    const [showNavigationList, setShowNavigationList]                                       = useState(false);
    const [animateOut, setAnimateOut]                                                       = useState(false);
    //console.log("user");
   // console.log(user);
   
   const [showLoginModalAuth, setShowLoginModalAuth]                                        = useState(false);
   const [showRegisterModalAuth, setShowRegisterModalAuth]                                  = useState(false);
    
   const [totalQuantity, setTotalQuantity]                                                  = useState(0);

   const dispatch                                                                           = useDispatch();

   const handleLogoutFeature = () => {
        localStorage.removeItem(CONSTANTS.SESSION_COOKIE);
        dispatch(logout());
    };

    const checkProductCount = () => {
        const cartData = localStorage.getItem(CONSTANTS.CART_COOKIE);
        if(cartData){
            const savedCartData         = JSON.parse(cartData);
            let totalQty = 0;
            savedCartData.forEach(item => {
                totalQty += item.quantity
            });

            setTotalQuantity(totalQty);
        }
    }

  useEffect(() => {
     checkProductCount();
  },[cartItems]);

  useEffect(() => {
    if(showNavigationList){
        setAnimateOut(true);  
        setTimeout(() => {         
          setAnimateOut(false);
      
        }, 5000); // adjust the timeout to match the animation duration
    }
  },[showNavigationList])


  return (
    <div className={"navigation-board "  + classFind} >
        <div className="navigation-inner">
            <div className="nav-section-logo">
                <Link to="/" className="btn btn-image"> 
                    <img src={HeaderLogo} className="logo-one" alt="CJ Marketing E-Commerce" />
                </Link>
            </div>
            <div className="nav-section-part">
                {
                    user === null ?
                    <div className="auth-row">
                        <button className="btn btn-action mgright10" onClick={() => setShowRegisterModalAuth(true)}>Register</button>
                        <button className="btn btn-action" onClick={() => setShowLoginModalAuth(true)}>Login</button>
                    </div>
                    :
                    <div className="user-details">
                        <span>{user.name}</span>
                        <span>{user.surname}</span>
                        <span className="basket-box">
                            <Link to="/cart">
                            <FaShoppingBasket /> 
                            <span className="basketTotal">{totalQuantity}</span>
                            </Link>
                        </span>
                    </div>
                }
                <button className="main-button-icon" onClick={() => setShowNavigationList(true)}>
                    <FaBars />
                </button>
            </div>
        </div>
        
        {
            showLoginModalAuth && (
                <div className="modal">
                    <div className="modal-content">
                        <div className="modal-header modal-fx">
                            <span className="closemd" onClick={() => setShowLoginModalAuth(false)}>&times;</span>
                            <h2>Login</h2>
                        </div>
                        <div className="modal-body">
                            <LoginModal />
                        </div>
                    </div>
                </div>
            )
        }
        {
            showRegisterModalAuth && (
                <div className="modal">
                    <div className="modal-content">
                        <div className="modal-header modal-fx">
                            <span className="closemd" onClick={() => setShowRegisterModalAuth(false)}>&times;</span>
                            <h2>Registration</h2>
                        </div>
                        <div className="modal-body">
                            <RegisterModal />
                        </div>
                    </div>
                </div>
            )
        }
        <div className={`base-full-navigation ${showNavigationList ? "show" : 'hide'} `}>
            <div className="close-row">
                <button className="main-button-icon close-x" onClick={() => setShowNavigationList(false)}>
                    X
                </button>
            </div>
            <div className="navigation-list">
                <ul className="link-ul">
                    <li><Link to="/about" className="btn btn-nav-link ">About</Link></li>
                    <li><Link to="/shop" className="btn btn-nav-link">Shop</Link></li>   
                    {
                        user !== null && (<>
                            <li><Link to="/orders" className="btn btn-nav-link">Orders</Link></li>  
                            <li><Link to="/profile" className="btn btn-nav-link">Profile</Link></li>   
                            <li><button className="btn btn-nav-link btn-logger" onClick={handleLogoutFeature}>Log Out</button></li>   
                        </>)
                    }                 
                </ul>
            </div>
        </div>
    </div>
  )
}

export default TopNavigation
/*
{
    showNavigationList && (
        <div className={`base-full-navigation ${showNavigationList ? "show" : ""}`}>
            <div className="close-row">
            <button className="main-button-icon close-x" onClick={() => setShowNavigationList(!showNavigationList)}>
                X
            </button>
            </div>
            <div className="navigation-list">
                <ul className="link-ul">
                    <li><Link to="/about" className="btn btn-nav-link ">About</Link></li>
                    <li><Link to="/shop" className="btn btn-nav-link">Shop</Link></li>
                    
                </ul>
            </div>
        </div>
    )
}
    */