import React from 'react';
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import { Autoplay} from 'swiper/modules';

function HeroSwiping({featured, slideChange}) {

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={10}      
      autoplay={{
        delay: 2000,
        disableOnInteraction: false
      }}
      breakpoints={{
        640:{
            slidesPerView:2,
            spaceBetween:10,   
        },
        768:{
            slidesPerView:3,
            spaceBetween:20,   
        },
        1024:{
            slidesPerView:4,
            spaceBetween:30,   
        },
      }}
      loop={true}
      modules={[Autoplay]}
      className="hero-on"
    >
        {
            featured.map((slide, index ) => (
                <SwiperSlide key={index}>
                    <img src={slide.pictureURL} alt={slide.title} className="product-slide-item" onClick={() => slideChange(index)} />
                </SwiperSlide>
            ))
        }
    </Swiper>
  )
}

export default HeroSwiping