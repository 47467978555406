import React, { useEffect, useState } from 'react'
import axios from 'axios';
import * as CONSTANTS from "../CONSTANTS";
import { Link } from 'react-router-dom';

function Shop() {

  const [productList, setProductList]                                 = useState([]);
  const [paginateFigure, setPaginationFigure]                         = useState(30);
  const [pageNumber, setPageNumber]                                   = useState(1);
  const [filterItems, setFilterItems]                                 = useState([]);

  const [processing, setProcessing]                                   = useState(false);
  useEffect(() => {
    collectShopProducts();
  },[])

  const collectShopProducts = async () => {
    try{
      setProcessing(true)
      const res = await axios.get(CONSTANTS.API_URL +"products/list/");
      console.log(res);
      console.log(res.data.list);
      setProductList(res.data);
      setProcessing(false)
    }catch(err){
      console.log(err);
      setProcessing(false)
    }
  }

  return (
    <div>
        <div className="section-row ">
          <div className="container standard-start">
             Shop
             <h2>Search</h2>
          </div>
        </div>
        <div className="section-row">
          <div className="container-full-part">
             <div className="flexme">
                <div className="flexy20 unflex side-shop">
                    <div className="side-fitlers-container">
                        <div className="filter-item filter-01">
                           <div clasName="filter-title"> Category</div>                           
                        </div>
                        <div className="filter-item filter-02">
                            <div clasName="filter-title">Prices</div>

                        </div>
                    </div>
                </div>
                <div className="flexy80 fullflex main-shop">
                   <div className="shop-container-outer">
                      <div className="shop-container-inner row">
                        {
                          productList && 
                          productList.list && 
                          productList.list.length > 0 && (
                            productList.list.map((product) => (
                               <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2" key={product._id}>
                                        <div className="product-box">
                                          <div className="image-pro">
                                             <Link to={"/product/" + product._id }>
                                             <img src={product.pictureURL} className="product-image" />
                                             </Link>
                                          </div>
                                          <h3 className="product-name">
                                              <Link to={"/product/" + product._id }>
                                                {product.title}
                                              </Link>
                                          </h3>
                                          <div className="product-price">R {product.price}</div>
                                        </div>
                                      </div>
                            ))
                          )
                        }
                      </div>
                   </div>
                </div>
             </div>
          </div>

          {
            processing && (
              <div className="processing-container">
                <img src={CONSTANTS.SMALL_PROCESSING} className="image-processing" />
              </div>
            )
          }
        </div>
    </div>
  )
}

export default Shop