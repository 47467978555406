import React, { useEffect, useState } from 'react'
import Hero from '../Components/Top/Hero';
import axios from 'axios';
import * as CONSTANTS from "../CONSTANTS";
import { Link } from 'react-router-dom';

function HomeScreen() {

  const [featuredProducts, setFeaturedProducts]                             = useState([]);
  const [randomProducts, setRandomProducts]                                 = useState();

  useEffect(() => {
    collectProductFeatures();
    collectRandom();
  }, [])

  const collectProductFeatures = async () =>{
    try{

      const res = await axios.get(CONSTANTS.API_URL +"products/list/published/featured");
      console.log(res);
      setFeaturedProducts(res.data);
    }catch(err){
      console.log(err)
    }
  }

  const collectRandom = async () =>{
    try{

      const res = await axios.get(CONSTANTS.API_URL +"products/list/random");
     console.log(res.data);
     setRandomProducts(res.data);
    }catch(err){
      console.log(err)
    }
  }
  return (
    <div className="standard-box">
        <Hero featuredProducts={featuredProducts} />
        <div className="section-row">
          <div className="container">
           {
            randomProducts &&
            randomProducts.length > 0 && (
              <div className="row">
                {
                  randomProducts.map((product, index) => {
                    return <div className="col-md-4" key={index}>
                       <Link to={"/product/" + product._id} className="home-link">
                            <div className="home-block" style={{ 
                                                  backgroundImage: `url("${product.pictureURL}")` 
                                                }}>
                                <div className="price-home">
                                  <div className="inner-price-home">
                                    R {product.price}
                                  </div>
                                </div>
                                <div className="title-outer">
                                  <div className="title-inner">
                                    {product.title}
                                  </div>
                                </div>
                                <div className="home-hover">                            
                                </div>
                            </div>
                      </Link>
                    </div>
                  })
                }
              </div>
            )
           }
          </div>
        </div>
    </div>
  )
}

export default HomeScreen