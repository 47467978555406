import React, { useState } from 'react'
import HeroSwiping from './HeroSwiping';
import { Link } from 'react-router-dom';

function Hero({featuredProducts}) {
  const [currentIndexActive, setCurrentIndexActive]                                  = useState(0);

  const handleSlideChangeEffect = id => {
   // console.log(id);
   // console.log(featuredProducts[id]);
    setCurrentIndexActive(id);
  }
  
  return (
    <div className={"banner curIn" + currentIndexActive}>
      {
        featuredProducts && 
        featuredProducts.length > 0 &&
          featuredProducts.map((feature, index)  =>
          <div className={"item p" + index} key={index}>
              <img src={feature.bannerPictureURL} className={`bgImage ${index === currentIndexActive ? "active" : ""} `} />
              <div className={`hero-content ${index === currentIndexActive ? "active" : ""}`}>
                    <div className="sub-description">
                        {feature.shortDescription}
                    </div>
                    <div className="heading-title">
                         {feature.title}
                    </div>
                    <div className="button-part">
                        <Link className="btn btn-black mgright10" to={"/product/"+ feature._id}>
                          View Product
                        </Link>
                        <Link className="btn btn-black" to={"/shop/"}>
                          View Shop
                        </Link>
                    </div>
              </div>
              
          </div>
        )
        
      }
      <div className="position-sliders">
         <HeroSwiping featured={featuredProducts} slideChange={handleSlideChangeEffect} />
      </div>
    </div>
  )
}

export default Hero