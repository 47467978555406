import React, { useEffect, useRef } from 'react'
import axios from "axios";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { login, reset } from '../../reduxAuth/authSlice';
import { useNavigate } from 'react-router-dom';

function LoginModal() {
    
    const emailRef                                                  = useRef();
    const passwordRef                                               = useRef();

    const navigate                                                  = useNavigate();
    const dispatch                                                  = useDispatch();

    const {user, isLoading, isError, isSuccess, message}            = useSelector((state) => state.auth);

    useEffect(() => {

        if(isError){
            toast.error(message)
        }
      
        if(isSuccess || user){
           // navigate('/scripts');
           console.log("LOGIN SITUATION");
           toast.success("You successfully logged in.")
        }

        dispatch(reset())
    },[user, isError, isSuccess, message, navigate, dispatch])

    const handleLogin = (e) => {
        e.preventDefault();
        try{

            console.log("handle login");

            const userData = {
                "email" : emailRef.current.value,
                "password" : passwordRef.current.value
            }

            dispatch(login(userData));
            console.log(userData);

        }catch(err){
            console.log(err)
        }
    }
  return (
    <div className="block-form">
        <div className="form-inner">      
            <form onSubmit={handleLogin}>
                 <div className="form-group frg mgtop10">
                    <div className="text-form">Email: </div>
                    <input type="email" className="form-control ct-content wide100" ref={emailRef}  placeholder="Enter Email Address" required/>
                </div>
                <div className="form-group frg mgtop10">
                    <div className="text-form">Password: </div>
                    <input type="password" className="form-control ct-content wide100"  ref={passwordRef} placeholder="Enter Password" required/>
                </div>
                <div className="form-group frg mgtop10">
                    <button className="btn btn-action btn-full ct" disabled={isLoading} >Login</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default LoginModal
