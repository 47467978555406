import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaSearch, FaTrash } from 'react-icons/fa';
import {incrementQuantity, decrementQuantity, removeItem, clearCart} from './../reduxAuth/cartSlice';
import * as CONSTANTS from "./../CONSTANTS";
import axios from 'axios';
 
function ShoppingCart() {
    
    const dispatch                                                 = useDispatch();
    const cartItems                                                = useSelector(state => state.cart.items);
    const {user}                                                   = useSelector((state) => state.auth);
    const [cartOrderMessage, setCartOrderMessage]                  = useState();
    const [classOrderMessage, setClassOrderMessage]                = useState();
    const [processing, setProcessing]                              = useState(false);    
    const [paymentCost, setPaymentCost]                            = useState(0.00);

    const handleIncrement = (productId) => {
        dispatch(incrementQuantity(productId));    
    };

    const handleDecrement = (productId) => {
       dispatch(decrementQuantity(productId));
    };


    const handleRemove = (productId) => {
        dispatch(removeItem(productId));
    }


    const calcualateTheTotalCost = () => {   

        let amountTotal = 0.00;
        cartItems.forEach(item => {
            if(item.product.price.length > 0){
                const newAmount = parseFloat(item.product.price) * item.quantity;
                amountTotal  = amountTotal + newAmount;
            }      

        });
        setPaymentCost(amountTotal);
    }

    const handleExecuteOrderingNow = async () => {

        try{

            /*
            console.log(user);
            console.log(cartItems);
            console.log("(((((((((((((((((0))))))))))))))))");
            */

            setProcessing(true);
            const cartLength        = cartItems.length;
            let newProductList      = [];
            let newMongoProductList = [];

            /////////////////////////////////////////////@********** */

               

            const orderCode = makeid(3) + "" + Math.floor(Math.random() * 900) + 100;

            for (let i = 0; i < cartLength; i++) { 

                const newItem = {
                    "cjdAccountNumber": user.cjdAccountNumber,
                    "ordercode": orderCode,
                    "orderstatus":"Initial",
                    "barcode" : cartItems[i].product.barcode,
                    "title" : cartItems[i].product.title,
                    "onlineDesc" : cartItems[i].product.onlineDesc + "",
                    "deliveryAddress": user.deliveryAddress,
                    "img_url" : cartItems[i].product.imgurl,
                    "price" : cartItems[i].product.price,
                    "qty" : cartItems[i].quantity

                }

                newMongoProductList.push(newItem);
             }

            /////////////////////////////////////////////@********** */

            const resOrders = await axios.post(CONSTANTS.API_URL + 'orders/create/v1', newMongoProductList); 

            ////////////////////////////////////////////********** */

            if(resOrders.status == 200){

                setCartOrderMessage("Your order was successfull.");
                setClassOrderMessage("alert-success");
                dispatch(clearCart());
            }else {
                setCartOrderMessage("Something went wrong with the order, please try again later.");
                setClassOrderMessage("alert-danger");

            }  
            setProcessing(false);
        }catch(err){
            setProcessing(false);
            console.log(err);
        }

    }

    function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;

        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;

        }
        return result;

    }

    useEffect(() => {
        calcualateTheTotalCost();
    },[cartItems])

 
  return (
    <div>
        <div className="section-row ">
            <div className="container standard-start">
                <h5 className="titling-head">Shopping Cart</h5>
                <div className="row-single">
                  {
                    (cartItems.length > 0)  ?
                      <div className="cart-listing">
                         {
                            cartItems.map(item => (
                                <div className="row rp-product" key={item.product._id}>
                                    <div className="col-md-4 col-sm-4">
                                        <div className="image-base">
                                            <img src={item.product.pictureURL} className="img-full" />
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="vail-product">
                                            <div className="product-content">
                                                <h5>{item.product.title}</h5>
                                                <div className="barcode-display1">{item.product.barcode}</div>
                                                <div className="sub-desc">{item.product.shortDescription}</div>
                                            </div>
                                            <div className="quantity-sect">
                                                <button className="btn btn-circle" onClick={() => handleDecrement(item.product._id)}> - </button>
                                                <div className="qtyShow">
                                                    {item.quantity}
                                                </div>
                                                <button className="btn btn-circle" onClick={() => handleIncrement(item.product._id)}> + </button>
                                            </div>
                                            <button className="btn btn-danger btn-close-product" onClick={() => handleRemove(item.product._id)}>
                                                <span><FaTrash /></span>
                                            </button>
                                            <div className="price-cart">
                                               R {item.product.price}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                         }
                      </div>
                      :
                      <h3>No cart has been created.</h3>
                  }
                </div>
                <div className="row">
                    <div className="col-md-7"></div>
                    <div className="col-md-5">
                        <div className="checkout">
                            <div className="checkount-title">
                                CHECK OUT
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                processing && (
                    <div className="processing-container">
                        <img src={CONSTANTS.SMALL_PROCESSING} className="image-processing" />
                    </div>
            )
          }
        </div>
    </div>
  )
}

export default ShoppingCart