import React from 'react'

function ProfileScreen() {
  return (
    <div>
         <div className="section-row ">
            <div className="container standard-start">
            ProfileScreen</div>
        </div>
    </div>
  )
}

export default ProfileScreen